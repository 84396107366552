import { rep_sc } from 'typescript-parsec';
import { buildLexer, expectEOF, expectSingleResult, rule } from 'typescript-parsec';
import { alt, apply, kmid, lrec_sc, seq, tok } from 'typescript-parsec';
export var K;
(function (K) {
    K[K["If"] = 0] = "If";
    K[K["Each"] = 1] = "Each";
    K[K["From"] = 2] = "From";
    K[K["To"] = 3] = "To";
    K[K["End"] = 4] = "End";
    K[K["Comment"] = 5] = "Comment";
    K[K["Number"] = 6] = "Number";
    K[K["Op1"] = 7] = "Op1";
    K[K["Op2"] = 8] = "Op2";
    K[K["Assign"] = 9] = "Assign";
    K[K["Comma"] = 10] = "Comma";
    K[K["Col"] = 11] = "Col";
    K[K["LP"] = 12] = "LP";
    K[K["RP"] = 13] = "RP";
    K[K["WS"] = 14] = "WS";
    K[K["Name"] = 15] = "Name";
    K[K["Compare"] = 16] = "Compare";
    K[K["And"] = 17] = "And";
    K[K["Or"] = 18] = "Or";
})(K || (K = {}));
const KW_ENGLISH = {
    If: 'if',
    Each: 'each',
    From: 'from',
    To: 'to',
    End: 'end',
    And: 'and',
    Or: 'or',
};
export const KW_HEBREW = {
    If: 'אם',
    Each: 'לכל',
    From: 'מ',
    To: 'עד',
    End: 'סוף',
    And: 'וגם',
    Or: 'או',
};
export function getLexer(keepWs = false, KW = KW_ENGLISH) {
    return buildLexer([
        [keepWs, /^\s+/g, K.WS],
        [true, /^#[^\n]*/g, K.Comment],
        [true, new RegExp(`^${KW.If}`, 'g'), K.If],
        [true, new RegExp(`^${KW.Each}`, 'g'), K.Each],
        [true, new RegExp(`^${KW.From}`, 'g'), K.From],
        [true, new RegExp(`^${KW.To}`, 'g'), K.To],
        [true, new RegExp(`^${KW.End}`, 'g'), K.End],
        [true, new RegExp(`^${KW.And}`, 'g'), K.And],
        [true, new RegExp(`^${KW.Or}`, 'g'), K.Or],
        [true, /^\d+/g, K.Number],
        [true, /^[<>]/g, K.Compare],
        [true, /^==/g, K.Compare],
        [true, /^>=/g, K.Compare],
        [true, /^<=/g, K.Compare],
        [true, /^=/g, K.Assign],
        [true, /^[*/%]/g, K.Op1],
        [true, /^[-+]/g, K.Op2],
        [true, /^,/g, K.Comma],
        [true, /^:/g, K.Col],
        [true, /^\(/g, K.LP],
        [true, /^\)/g, K.RP],
        [true, /^[a-zא-ת][a-z_א-ת0-9]*/g, K.Name],
    ]);
}
const ops = {
    '-': (a, b) => a - b,
    '+': (a, b) => a + b,
    '*': (a, b) => a * b,
    '/': (a, b) => a / b,
    '%': (a, b) => a % b,
};
export function parse(input, { lexer = getLexer(), host = { vars: {}, funcs: {} } } = {}) {
    function n(num) {
        return +num.text;
    }
    function expVar(name) {
        return {
            eval: () => {
                const v = name.text;
                if (!(v in host.vars)) {
                    host.vars[v] = 0;
                }
                return host.vars[v];
            }
        };
    }
    function expNum(num) {
        return {
            eval: () => n(num)
        };
    }
    function expOp(first, tail) {
        return {
            eval: () => ops[tail[0].text](first.eval(), tail[1].eval())
        };
    }
    function expFuncCall(value) {
        return {
            eval: () => {
                const [name, args] = value;
                if (!(name.text in host.funcs)) {
                    host.funcs[name.text] = (...args) => 0;
                }
                const vals = args.map(x => x.eval());
                return host.funcs[name.text](...vals);
            }
        };
    }
    function args(args, arg) {
        args.push(arg[1]);
        return args;
    }
    const ARGS = rule();
    const TERM = rule();
    const FACTOR = rule();
    const EXP = rule();
    const COMPARE = rule();
    const AND = rule();
    const BOOL = rule();
    const FUNC_CALL = rule();
    const ASSIGN = rule();
    const EACH = rule();
    const IF = rule();
    const COMMENT = rule();
    const STMT = rule();
    const PROG = rule();
    ARGS.setPattern(alt(apply(seq(tok(K.LP), tok(K.RP)), () => []), kmid(tok(K.LP), lrec_sc(apply(EXP, e => [e]), seq(tok(K.Comma), EXP), args), tok(K.RP))));
    const compare = {
        '<': (a, b) => a < b,
        '>': (a, b) => a > b,
        '>=': (a, b) => a >= b,
        '<=': (a, b) => a <= b,
        '==': (a, b) => a === b,
    };
    function expCompare(value) {
        const [e1, c, e2] = value;
        return {
            eval: () => compare[c.text](e1.eval(), e2.eval())
        };
    }
    COMPARE.setPattern(alt(EXP, apply(seq(EXP, tok(K.Compare), EXP), expCompare)));
    function expAnd(e1, second) {
        const [_, e2] = second;
        return {
            eval: () => e1.eval() && e2.eval()
        };
    }
    AND.setPattern(lrec_sc(COMPARE, seq(tok(K.And), COMPARE), expAnd));
    function expBool(e1, second) {
        const [_, e2] = second;
        return {
            eval: () => e1.eval() || e2.eval()
        };
    }
    BOOL.setPattern(lrec_sc(AND, seq(tok(K.Or), AND), expBool));
    TERM.setPattern(alt(apply(tok(K.Name), expVar), apply(tok(K.Number), expNum), kmid(tok(K.LP), EXP, tok(K.RP))));
    FACTOR.setPattern(lrec_sc(TERM, seq(tok(K.Op1), TERM), expOp));
    EXP.setPattern(alt(lrec_sc(FACTOR, seq(tok(K.Op2), FACTOR), expOp), FUNC_CALL));
    FUNC_CALL.setPattern(apply(seq(tok(K.Name), ARGS), expFuncCall));
    function expEach(value) {
        const [$1, name, $2, fromExp, $3, toExp, $4, prog, $5] = value;
        return {
            eval: () => {
                for (let i = fromExp.eval(); i <= toExp.eval(); i++) {
                    host.vars[name.text] = i;
                    prog.forEach(s => s.eval());
                }
                return 0;
            }
        };
    }
    EACH.setPattern(apply(seq(tok(K.Each), tok(K.Name), tok(K.From), EXP, tok(K.To), EXP, tok(K.Col), PROG, tok(K.End)), expEach));
    function expIf(value) {
        const [$1, cond, $2, prog, $3] = value;
        return {
            eval: () => {
                const v = cond.eval();
                if (v) {
                    prog.forEach(s => s.eval());
                }
                return v;
            }
        };
    }
    IF.setPattern(apply(seq(tok(K.If), BOOL, tok(K.Col), PROG, tok(K.End)), expIf));
    function expAssign(value) {
        const [name, _, exp] = value;
        return {
            eval: () => {
                return host.vars[name.text] = exp.eval();
            }
        };
    }
    ASSIGN.setPattern(apply(seq(tok(K.Name), tok(K.Assign), EXP), expAssign));
    COMMENT.setPattern(apply(tok(K.Comment), () => { return { eval: () => 0 }; }));
    STMT.setPattern(alt(ASSIGN, FUNC_CALL, EACH, IF, COMMENT));
    PROG.setPattern(rep_sc(STMT));
    return expectSingleResult(expectEOF(PROG.parse(lexer.parse(input))));
}
